import React from "react"
import BaseTemplateLayout from "./BaseTemplateLayout"
import { graphql } from "gatsby"
import OutlinedButton from "../shared/components/OutlinedButton"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader"
deckDeckGoHighlightElement()

const shortCodes = { OutlinedButton }

export const query = graphql`
  query($slug: String!) {
    post: contentfulBlogPost(slug: { eq: $slug }) {
      title
      author
      date: createdAt
      slug
      todayILearned
      content {
        mdx: childMdx {
          body
        }
      }
    }
  }
`

const BlogPostTemplate = ({ data: { post } }) => {
  return (
    <BaseTemplateLayout>
      <h3>{post.author}</h3>
      <h1>{post.title}</h1>
      <h5>
        &rarr; {post.date.split("T")[0]}{" "}
        {post.todayILearned ? "(Today I Learned)" : ""}
      </h5>
      <MDXProvider components={shortCodes}>
        <MDXRenderer>{post.content.mdx.body}</MDXRenderer>
      </MDXProvider>
    </BaseTemplateLayout>
  )
}

export default BlogPostTemplate
